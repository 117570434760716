module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/runner/work/adri.github.io/adri.github.io/gatsby/node_modules/gatsby-remark-images","id":"5519bc38-891b-5019-b424-4de9e6e4fd63","name":"gatsby-remark-images","version":"3.3.25","pluginOptions":{"plugins":[],"backgroundColor":"transparent","linkImagesToOriginal":true,"maxWidth":960,"quality":90,"tracedSVG":true,"withWebp":true},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-line-breaks","gatsby-remark-external-links",{"resolve":"gatsby-remark-images","options":{"backgroundColor":"transparent","linkImagesToOriginal":true,"maxWidth":960,"quality":90,"tracedSVG":true,"withWebp":true}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","linkImagesToOriginal":true,"maxWidth":960,"quality":90,"tracedSVG":true,"withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Adrian Philipp","short_name":"adrian-philipp","description":" I’m a software and site reliability engineer. My drive is to make technology easy to use and simple to develop.","start_url":"/","background_color":"#fff","theme_color":"#6B46C1","display":"standalone","icons":[{"src":"/android-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
